<template>
  <div>
    <b-card-code title="Update sensor" :before-change="validationForm">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <!-- active -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Active"
                rules="required"
              >
                <b-form-group
                  label="Active"
                  label-for="active"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="active"
                    v-model="activeSelect"
                    :state="activeSelect === null ? false : true"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="activeOptions"
                    :selectable="
                      (option) => !option.value.includes('select_value')
                    "
                    label="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- type -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Type"
                rules="required"
              >
                <b-form-group
                  label="Type"
                  label-for="type"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="type"
                    v-model="typeSelect"
                    :state="typeSelect === null ? false : true"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="typeOptions"
                    :selectable="
                      (option) => !option.value.includes('select_value')
                    "
                    label="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- OS type -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="OS Type"
                rules="required"
              >
                <b-form-group
                  label="Type"
                  label-for="OS type"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="os-type"
                    v-model="osTypeSelect"
                    :state="osTypeSelect === null ? false : true"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="OSTypeOptions"
                    :selectable="
                      (option) => !option.value.includes('select_value')
                    "
                    label="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- is Score -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Is Score"
                rules="required"
              >
                <b-form-group
                  label="Is Score"
                  label-for="is score"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="is-score"
                    v-model="isScoreSelect"
                    :state="isScoreSelect === null ? false : true"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="isScoreOptions"
                    :selectable="
                      (option) => !option.value.includes('select_value')
                    "
                    label="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Score -->
            <b-col
              md="6"
              v-if="isScoreSelect != null && isScoreSelect.value == '1'"
            >
              <b-form-group label="Score" label-for="Score">
                <validation-provider
                  #default="{ errors }"
                  name="Score"
                  rules="required|regex:^[0]*[1-9][0-9]*$"
                >
                  <b-form-input
                    id="Score"
                    min="1"
                    type="number"
                    v-model="data.score"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <hr />
            </b-col>
            <!-- submit button -->
            <b-col>
              <b-button
                size="lg"
                variant="primary"
                type="submit"
                @click.prevent="validationForm"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-code>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">
        Alert
      </h4>
      <div class="alert-body">
        <ul v-for="values in errors_back" :key="values">
                    <li v-for="value in values" :key="value">{{value}}</li>
                </ul>
      </div>
    </b-alert>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import { heightTransition } from "@core/mixins/ui/transition";
import { required } from "@validations";
export default {
  components: {
    BCardCode,
  },
  
  mixins: [heightTransition],
  data() {
    return {
      id: 0,
      activeSelect: {
        value: "",
        text: "",
      },
      activeOptions: [
        {
          value: "select_value",
          text: "Select Value",
        },
        {
          value: "0",
          text: "False",
        },
        {
          value: "1",
          text: "True",
        },
      ],
      isScoreSelect: {
        value: "",
        text: "",
      },
      isScoreOptions: [
        {
          value: "select_value",
          text: "Select Value",
        },
        {
          value: "0",
          text: "False",
        },
        {
          value: "1",
          text: "True",
        },
      ],
      typeSelect: {
        value: "",
        text: "",
      },
      typeOptions: [
        {
          value: "select_value",
          text: "Select Value",
        },
        {
          value: "string",
          text: "String",
        },
        {
          value: "percentage ",
          text: "Percentage ",
        },
        {
          value: "fixed",
          text: "Fixed",
        },
      ],
      OSTypeOptions: [
        {
          value: "select_value",
          text: "Select Value",
        },
        {
          value: "ios",
          text: "iOS",
        },
        {
          value: "android",
          text: "Android",
        },
        {
          value: "all",
          text: "All",
        },
      ],
      osTypeSelect: {
        value: "",
        text: "",
      },
      data: {
        active: "",
        type: "",
        os_type: "",
        is_score: "",
        score: 1,
      },
      errors_back: [],
      showDismissibleAlert: false,
      required,
    };
  },
  created() {
    this.id = this.$route.params.id;
    axios
      .get("sensors/" + this.id)
      .then((result) => {
        const data = result.data.data;
        if (data.type == "percentage") {
          this.typeSelect.value = "percentage";
          this.typeSelect.text = "Percentage";
        } else if (data.type == "fixed") {
          this.typeSelect.value = "fixed";
          this.typeSelect.text = "Fixed";
        } else {
          this.typeSelect.value = "string";
          this.typeSelect.text = "String";
        }
        if (data.os_type == "all") {
          this.osTypeSelect.value = "all";
          this.osTypeSelect.text = "All";
        } else if (data.os_type == "android ") {
          this.osTypeSelect.value = "android";
          this.osTypeSelect.text = "Android";
        } else {
          this.osTypeSelect.value = "ios";
          this.osTypeSelect.text = "iOS";
        }
        if (data.active == true) {
          this.activeSelect.value = "1";
          this.activeSelect.text = "True";
        } else {
          this.activeSelect.value = "0";
          this.activeSelect.text = "False";
        }
        if (data.is_score == true) {
          this.isScoreSelect.value = "1";
          this.isScoreSelect.text = "True";
          this.data.score == data.score;
        } else {
          this.isScoreSelect.value = "0";
          this.isScoreSelect.text = "false";
        }
        this.data.score = data.score;
      })
      .catch((err) => {
        this.errors_back.length = 0;
        if (err.response.data.data != null) {
          if (this.isString(err.response.data.data)) {
            this.errors_back.push({
              error: err.response.data.data,
            });
          } else {
            this.errors_back = err.response.data.data;
          }
          this.showDismissibleAlert = true;
        } else {
          this.errors_back = [];
          this.errors_back.push({
            error: "internal server error",
          });
          this.showDismissibleAlert = true;
        }
      });
  },
  methods: {
    // submit Brand API
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.data.active = this.activeSelect.value;
          this.data.type = this.typeSelect.value;
          this.data.is_score = this.isScoreSelect.value;
          this.data.os_type = this.osTypeSelect.value;
          const sendData = this.data;
          if (this.isScoreSelect.value != "1") {
            delete sendData.Score;
          }
          axios
            .put("sensors/" + this.id, sendData)
            .then((result) => {
              this.$swal({
                position: "center",
                icon: "success",
                title: "Your work has been saved",
                showConfirmButton: false,
                timer: 1500,
              });
              this.$router.back(1);
            })
            .catch((err) => {
              this.$swal({
                position: "center",
                icon: "error",
                title: "Error!",
                showConfirmButton: false,
                timer: 1500,
              });
              this.errors_back.length = 0;
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  });
                } else {
                  this.errors_back = err.response.data.data;
                }
                this.showDismissibleAlert = true;
              } else {
                this.errors_back = [];
                this.errors_back.push({
                  error: "internal server error",
                });
                this.showDismissibleAlert = true;
              }
            });
        }
      });
    },
    isString(value) {
      return typeof value === "string" || value instanceof String;
    },
    // basic
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style lang="scss" scoped></style>
